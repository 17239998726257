export default {
  headerTitle: {
    id: 'buyback_address_header_title',
    defaultMessage: '2/3 Informations personnelles',
  },
  formTitle: {
    id: 'buyback_address_form_title',
    defaultMessage: 'Promis on en prendra soin',
  },
  formDescription: {
    id: 'buyback_address_form_description',
    defaultMessage:
      'Notre prestataire de paiement a besoin des informations suivantes pour effectuer la transaction. Vous devez être majeur pour vendre votre appareil.',
  },
  formBirthLabel: {
    id: 'buyback_address_form_birth_label',
    defaultMessage: 'Date de naissance',
  },
  formBirthDescription: {
    id: 'buyback_address_form_birth_description',
    defaultMessage: 'Ex : 16/09/1985',
  },
  formAddressLabel: {
    id: 'buyback_address_form_address_label',
    defaultMessage: 'Adresse',
  },
  formAddressDescription: {
    id: 'buyback_address_form_address_description',
    defaultMessage: 'Ex : 3, rue du reconditionnement',
  },
  formAddressComplementLabel: {
    id: 'buyback_address_form_address_complement_label',
    defaultMessage: 'Complément d’adresse (optionnel)',
  },
  formAddressComplementDescription: {
    id: 'buyback_address_form_address_complement_description',
    defaultMessage: 'Ex : Étage, bâtiment, digicode',
  },
  formCityLabel: {
    id: 'buyback_address_form_city_label',
    defaultMessage: 'Ville',
  },
  formCityDescription: {
    id: 'buyback_address_form_city_description',
    defaultMessage: 'Ex : Pantin',
  },
  formZipLabel: {
    id: 'buyback_address_form_zip_label',
    defaultMessage: 'Code Postal',
  },
  formZipDescription: {
    id: 'buyback_address_form_zip_description',
    defaultMessage: 'Ex: 75 020',
  },
  formStateLabel: {
    id: 'buyback_address_form_state_label',
    defaultMessage: 'State',
  },
  formCountryLabel: {
    id: 'buyback_address_form_country_label',
    defaultMessage: 'Pays',
  },
  formPhoneLabel: {
    id: 'buyback_address_form_phone_label',
    defaultMessage: 'Téléphone',
  },
  formPhoneDescription: {
    id: 'buyback_address_form_phone_description',
    defaultMessage: 'Ex: 06 30 81 34 31',
  },
  formPhoneClearLabel: {
    id: 'buyback_address_form_phone_clear_label',
    defaultMessage: 'Clear',
  },
  formLegalMessage: {
    id: 'buyback_address_form_legal_message',
    defaultMessage:
      "J'accepte de céder mon produit conformément aux {link, html} et certifie que ces informations sont exactes",
  },
  formCgr: {
    id: 'buyback_address_form_legal_cgr',
    defaultMessage: 'Conditions générales de reprise',
  },
  formMandatoryError: {
    id: 'buyback_address_form_mandatory_error',
    defaultMessage: 'Le champs est obligatoire',
  },
  formBirthError: {
    id: 'buyback_address_form_birth_error',
    defaultMessage: 'You must be major of age in order to sell',
  },
  postalCodeLengthError: {
    id: 'buyback_address_form_length_error',
    defaultMessage: 'The field should do {length} characters',
  },
  formSubmitButton: {
    id: 'buyback_address_form_submit',
    defaultMessage: 'Continuer',
  },
  formApiError: {
    id: 'buyback_address_form_api_error',
    defaultMessage: 'Erreur interne, veuillez réessayer',
  },
  formErrorUnableToProcess: {
    id: 'buyback_error_unable_to_process',
    defaultMessage:
      'It looks like your trade-in is in progress on another browser or device. Double check and try again.',
  },
  formErrorListingNotFound: {
    id: 'buyback_error_listing_not_found',
    defaultMessage:
      'This offer has expired. Get another offer to complete your trade-in.',
  },
}
